import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FrontendComponent} from '@frontend/frontend.component';
import {IsLoggedInGuard} from '@shared/guards/is-logged-in.guard';
import {HasAssignedModuleGuard} from '@shared/guards/has-assigned-module.guard';
import {ModuleName} from '@shared/models/module-name.model';
import {ModuleColor} from '@shared/models/module-color';

const routes: Routes = [
  {
    path: '',
    component: FrontendComponent,
    canActivate: [IsLoggedInGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('@frontend/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [IsLoggedInGuard],
        data: {
          title: 'dashboard',
          color: ModuleColor.NAVIGATION,

        }
      },
      {
        path: 'online/start',
        loadChildren: () => import('@frontend/modules/online-start/online-start.module').then(m => m.OnlineStartModule),
        canActivate: [IsLoggedInGuard],
        data: {
          title: 'onlineSchooling',
          color: ModuleColor.SCHOOLING,
        }
      },
      {
        path: 'app/schooling',
        loadChildren: () => import('@frontend/modules/app/app-online-schooling/app-online-schooling.module').then(m => m.AppOnlineSchoolingModule),
        canActivate: [IsLoggedInGuard],
        data: {
          title: 'onlineSchooling',
          color: ModuleColor.SCHOOLING,
        }
      },
      {
        path: 'employee',
        loadChildren: () => import('@frontend/modules/employee/employee.module').then(m => m.EmployeeModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'employees',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'employee-category',
        loadChildren: () => import('@frontend/modules/employee-category/employee-category.module').then(m => m.EmployeeCategoryModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'employeeCategories',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'file-system',
        loadChildren: () => import('@frontend/modules/file-system/file-system.module').then(m => m.FileSystemModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'fileStorage',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'symbol-icon',
        loadChildren: () => import('@frontend/modules/symbol-icon/symbol-icon.module').then(m => m.SymbolIconModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'symbol-icon',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'roles',
        loadChildren: () => import('@frontend/modules/role/role.module').then(m => m.RoleModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'roles',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'area-location',
        loadChildren: () => import('@frontend/modules/area/area.module').then(m => m.AreaModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'locations',
          color: ModuleColor.USER_ADMINISTRATION,
          module: ModuleName.USER_ADMINISTRATION,
        },
      },
      {
        path: 'cost-center',
        loadChildren: () => import('@frontend/modules/cost-center/cost-center.module').then(m => m.CostCenterModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'costCenters',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'license',
        loadChildren: () => import('@frontend/modules/license/license.module').then(m => m.LicenseModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'licenses',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'analyse',
        loadChildren: () => import('@frontend/modules/analyse/analyse.module').then(m => m.AnalyseModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'analyses',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'department',
        loadChildren: () => import('@frontend/modules/department/department.module').then(m => m.DepartmentModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'corporateStructure',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,

        },
      },
      {
        path: 'hazard-assessment',
        loadChildren: () => import('@frontend/modules/hazard-assessment/hazard-assessment.module').then(m => m.HazardAssessmentModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'hazard-overview',
          module: ModuleName.HAZARD_ASSESSMENT,
          color: ModuleColor.HAZARD_ASSESSMENT,
        },
      },
      {
        path: 'hazard-assessment-template',
        loadChildren: () => import('@frontend/modules/hazard-assessment-template/hazard-assessment-template.module').then(m => m.HazardAssessmentTemplateModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'templates',
          module: ModuleName.HAZARD_ASSESSMENT,
          color: ModuleColor.HAZARD_ASSESSMENT,
        },
      },
      {
        path: 'hazard-assessment-template-category',
        loadChildren: () => import('@frontend/modules/hazard-assessment-template-category/hazard-assessment-template-category.module').then(m => m.HazardAssessmentTemplateCategoryModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'templateCategories',
          module: ModuleName.HAZARD_ASSESSMENT,
          color: ModuleColor.HAZARD_ASSESSMENT,
        },
      },
      {
        path: 'hazard-group',
        loadChildren: () => import('@frontend/modules/hazard-group/hazard-group.module').then(m => m.HazardGroupModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'groupsAndFactors',
          module: ModuleName.HAZARD_ASSESSMENT,
          color: ModuleColor.HAZARD_ASSESSMENT,
        },
      }, {
        path: 'hazard-checklist',
        loadChildren: () => import('@frontend/modules/hazard-checklist/hazard-checklist.module').then(m => m.HazardChecklistModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'checklists',
          module: ModuleName.HAZARD_ASSESSMENT,
          color: ModuleColor.HAZARD_ASSESSMENT,
        },
      }, {
        path: 'hazard-law',
        loadChildren: () => import('@frontend/modules/hazard-assessment-law/hazard-assessment-law.module').then(m => m.HazardAssessmentLawModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'lexica',
          module: ModuleName.HAZARD_ASSESSMENT,
          color: ModuleColor.HAZARD_ASSESSMENT,
        },
      }, {
        path: 'hazard-rating-type',
        loadChildren: () => import('@frontend/modules/rating-type/rating-type.module').then(m => m.RatingTypeModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'riskAssessments',
          module: ModuleName.HAZARD_ASSESSMENT,
          color: ModuleColor.HAZARD_ASSESSMENT,
        },
      },
      {
        path: 'instruction',
        loadChildren: () => import('@frontend/modules/instruction/instruction.module').then(m => m.InstructionModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'instruction-overview',
          module: ModuleName.INSTRUCTION,
          color: ModuleColor.INSTRUCTION,
        },
      },
      {
        path: 'qualification',
        loadChildren: () => import('@frontend/modules/qualification/qualification.module').then(m => m.QualificationModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'qualification-overview',
          module: ModuleName.QUALIFICATION,
          color: ModuleColor.QUALIFICATION,
        },
      },
      {
        path: 'qualification-employee',
        loadChildren: () => import('@frontend/modules/qualification-employee/qualification-employee.module').then(m => m.QualificationEmployeeModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'qualificationsAssigned',
          module: ModuleName.QUALIFICATION,
          color: ModuleColor.QUALIFICATION,
        },
      },
      {
        path: 'qualification-type',
        loadChildren: () => import('@frontend/modules/qualification-type/qualification-type.module').then(m => m.QualificationTypeModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'qualificationTypes',
          module: ModuleName.QUALIFICATION,
          color: ModuleColor.QUALIFICATION,
        },
      },
      {
        path: 'credential',
        loadChildren: () => import('@frontend/modules/credential/credential.module').then(m => m.CredentialModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'credential',
          module: ModuleName.QUALIFICATION,
          color: ModuleColor.QUALIFICATION,
        },
      },
      {
        path: 'observation',
        loadChildren: () => import('@frontend/modules/observation/observation.module').then(m => m.ObservationModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'observation-overview',
          module: ModuleName.OBSERVATION,
          color: ModuleColor.OBSERVATION,
        },
      },
      {
        path: 'observation-type',
        loadChildren: () => import('@frontend/modules/observation-type/observation-type.module').then(m => m.ObservationTypeModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'observationTypes',
          module: ModuleName.OBSERVATION,
          color: ModuleColor.OBSERVATION,
        },
      },
      {
        path: 'audit',
        loadChildren: () => import('@frontend/modules/audit/audit.module').then(m => m.AuditModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'audit-overview',
          module: ModuleName.AUDIT,
          color: ModuleColor.AUDIT,

        }
      },
      {
        path: 'inspection',
        loadChildren: () => import('@frontend/modules/inspection/inspection.module').then(m => m.InspectionModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'inspection-overview',
          module: ModuleName.AUDIT,
          color: ModuleColor.AUDIT,
        }
      },
      {
        path: 'external-company',
        loadChildren: () => import('@frontend/modules/external-company/external-company.module').then(m => m.ExternalCompanyModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'external-company-overview',
          module: ModuleName.EXTERNAL_COMPANIES,
          color: ModuleColor.EXTERNAL_COMPANIES,

        },
      },
      {
        path: 'external-company-employee',
        loadChildren: () => import('@frontend/modules/external-company-employee/external-company-employee.module').then(m => m.ExternalCompanyEmployeeModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'overViewExternalCompanyEmployees',
          module: ModuleName.EXTERNAL_COMPANIES,
          color: ModuleColor.EXTERNAL_COMPANIES,

        },
      },
      {
        path: 'external-company-category',
        loadChildren: () => import('@frontend/modules/external-company-category/external-company-category.module').then(m => m.ExternalCompanyCategoryModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'externalCompanyCategories',
          module: ModuleName.EXTERNAL_COMPANIES,
          color: ModuleColor.EXTERNAL_COMPANIES,

        },
      },
      {
        path: 'terminals',
        loadChildren: () => import('@frontend/modules/terminals/terminals.module').then(m => m.TerminalsModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'terminals',
          module: ModuleName.EXTERNAL_COMPANIES,
          color: ModuleColor.EXTERNAL_COMPANIES,

        },
      },
      {
        path: 'accident-book',
        loadChildren: () => import('@frontend/modules/accident-book/accident-book.module').then(m => m.AccidentBookModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'accident-book-overview',
          module: ModuleName.MEDICAL,
          color: ModuleColor.MEDICAL,

        },
      },
      {
        path: 'accident-preReport',
        loadChildren: () => import('@frontend/modules/accident-pre-report/accident-pre-report.module').then(m => m.AccidentPreReportModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'accident-preReport-overview',
          module: ModuleName.MEDICAL,
          color: ModuleColor.MEDICAL,

        },
      },
      {
        path: 'screening',
        loadChildren: () => import('@frontend/modules/screening/screening.module').then(m => m.ScreeningModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'screening-overview',
          module: ModuleName.SCREENING,
          color: ModuleColor.SCREENING
        },
      },
      {
        path: 'screening-examination',
        loadChildren: () => import('@frontend/modules/screening-examination/screening-examination.module').then(m => m.ScreeningExaminationModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'examination-overview',
          module: ModuleName.SCREENING,
          color: ModuleColor.SCREENING
        },
      },
      {
        path: 'screening-examination-screening',
        loadChildren: () => import('@frontend/modules/screening-examination-screening/screening-examination-screening.module').then(m => m.ScreeningExaminationScreeningModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'examination-overview-screening',
          module: ModuleName.SCREENING,
          color: ModuleColor.SCREENING
        },
      },
      {
        path: 'screening-package',
        loadChildren: () => import('@frontend/modules/screening-package/screening-package.module').then(m => m.ScreeningPackageModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'screening-package-overview',
          module: ModuleName.SCREENING,
          color: ModuleColor.SCREENING
        },
      },
      {
        path: 'schooling',
        loadChildren: () => import('@frontend/modules/schooling/schooling.module').then(m => m.SchoolingModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'personalSchoolings',
          module: ModuleName.SCHOOLING,
          color: ModuleColor.SCHOOLING
        },
      },
      {
        path: 'schooling-plan',
        loadChildren: () => import('@frontend/modules/schooling-plan/schooling-plan.module').then(m => m.SchoolingPlanModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'schoolingPlanOverviewEmployee',
          module: ModuleName.SCHOOLING,
          color: ModuleColor.SCHOOLING
        },
      },
      {
        path: 'schooling-plan-topic',
        loadChildren: () => import('@frontend/modules/schooling-plan-topic/schooling-plan-topic.module').then(m => m.SchoolingPlanTopicModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'schoolingPlanTopicOverviewStatus',
          module: ModuleName.SCHOOLING,
          color: ModuleColor.SCHOOLING
        },
      },
      {
        path: 'schooling-topic',
        loadChildren: () => import('@frontend/modules/schooling-topic/schooling-topic.module').then(m => m.SchoolingTopicModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'schoolingTopicOverview',
          module: ModuleName.SCHOOLING,
          color: ModuleColor.SCHOOLING
        },
      },
      {
        path: 'schooling-topic-category',
        loadChildren: () => import('@frontend/modules/schooling-topic-category/schooling-topic-category.module').then(m => m.SchoolingTopicCategoryModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'topicCategories',
          module: ModuleName.SCHOOLING,
          color: ModuleColor.SCHOOLING
        },
      },
      {
        path: 'schooling-package',
        loadChildren: () => import('@frontend/modules/schooling-package/schooling-package.module').then(m => m.SchoolingPackageModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'schoolingPackageOverview',
          module: ModuleName.SCHOOLING,
          color: ModuleColor.SCHOOLING
        },
      },
      {
        path: 'measure',
        loadChildren: () => import('@frontend/modules/measure/measure.module').then(m => m.MeasureModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'measure-overview',
          module: ModuleName.Measure,
          color: ModuleColor.MEASURE,
        },
      },
      {
        path: 'measure-type',
        loadChildren: () => import('@frontend/modules/measure-type/measure-type.module').then(m => m.MeasureTypeModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'measureTypes',
          module: ModuleName.Measure,
          color: ModuleColor.MEASURE,
        },
      },
      {
        path: 'permission-profile',
        loadChildren: () => import('@frontend/modules/permission-profile/permission-profile.module').then(m => m.PermissionProfileModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'permissionProfiles',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'settings',
        loadChildren: () => import('@frontend/modules/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'personalSettings',
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'translations',
        loadChildren: () => import('@frontend/modules/translations/translations.module').then(m => m.TranslationsModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'translations',
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'mailingSettings',
        loadChildren: () => import('@frontend/modules/mailing-system/mailing-system.module').then(m => m.MailingSystemModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'notificationSystem',
          module: ModuleName.USER_ADMINISTRATION,
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'report-error',
        loadChildren: () => import('@frontend/modules/report-error/report-error.module').then(m => m.ReportErrorModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'reportError',
        },
      },
      {
        path: '',
        redirectTo: 'dashboard',
        // canActivate: [IsLoggedInGuard],
        pathMatch: 'full',
      },
      {
        path: 'checklist',
        loadChildren: () => import('@frontend/modules/checklist/checklist.module').then(m => m.ChecklistModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'checklists',
          color: ModuleColor.USER_ADMINISTRATION,
        },
      },
      {
        path: 'inspection-checklist',
        loadChildren: () => import('@frontend/modules/inspection-checklist/inspection-checklist.module').then(m => m.InspectionChecklistModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'checklists',
          color: ModuleColor.AUDIT,
        },
      },
      {
        path: 'maintenance-equipment',
        loadChildren: () => import('@frontend/modules/maintenance-equipment/maintenance-equipment.module').then(m => m.MaintenanceEquipmentModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'equipmentOverview',
          color: ModuleColor.MAINTENANCEPLANNER,
        },
      },
      {
        path: 'maintenance-equipment-category',
        loadChildren: () => import('@frontend/modules/maintenance-equipment-category/maintenance-equipment-category.module').then(m => m.MaintenanceEquipmentCategoryModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'equipmentCategories',
          color: ModuleColor.MAINTENANCEPLANNER,
        },
      },
      {
        path: 'maintenance-exam-execution',
        loadChildren: () => import('@frontend/modules/maintenance-exam-execution/maintenance-exam.module').then(m => m.MaintenanceExamModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'examExecutionOverview',
          color: ModuleColor.MAINTENANCEPLANNER,
        },
      },
      {
        path: 'maintenance-exam',
        loadChildren: () => import('@frontend/modules/maintenance-exam/maintenance-exam.module').then(m => m.MaintenanceExamModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'examOverview',
          color: ModuleColor.MAINTENANCEPLANNER,
        },
      },
      {
        path: 'maintenance-exam-type',
        loadChildren: () => import('@frontend/modules/maintenance-exam-type/maintenance-exam-type.module').then(m => m.MaintenanceExamTypeModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'examTypes',
          color: ModuleColor.MAINTENANCEPLANNER,
        },
      },
      {
        path: 'maintenance-checklist',
        loadChildren: () => import('@frontend/modules/maintenance-checklist/maintenance-checklist.module').then(m => m.MaintenanceChecklistModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'checklists',
          color: ModuleColor.MAINTENANCEPLANNER,
        },
      },
      {
        path: 'instruction-pattern',
        loadChildren: () => import('@frontend/modules/pattern-instruction/pattern-instruction.module').then(m => m.PatternInstructionModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'templates',
          color: ModuleColor.INSTRUCTION,
        },
      },
      {
        path: 'instruction-template',
        loadChildren: () => import('@frontend/modules/instruction-template/instruction-template.module').then(m => m.InstructionTemplateModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'textblocks',
          color: ModuleColor.INSTRUCTION,
        },
      },
      {
        path: 'instruction-type',
        loadChildren: () => import('@frontend/modules/instruction-type/instruction-type.module').then(m => m.InstructionTypeModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'instructionTypes',
          color: ModuleColor.INSTRUCTION,
        },
      },
      {
        path: 'navigation',
        loadChildren: () => import('@frontend/modules/sub-navigation/sub-navigation.module').then(m => m.SubNavigationModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'navigation',
        },
      },
      {
        path: 'hazardousSubstance',
        loadChildren: () => import('@frontend/modules/hazardousSubstance/hazardousSubstance.module').then(m => m.HazardousSubstanceModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'hazardousSubstances',
          module: ModuleName.HAZARDOUS_SUBSTANCE,
          color: ModuleColor.HAZARDOUS_SUBSTANCE,
        },
      },
      {
        path: 'adr-hazardous',
        loadChildren: () => import('@frontend/modules/adr-hazardous/adr-hazardous.module').then(m => m.AdrHazardousModule),
        canActivate: [HasAssignedModuleGuard, IsLoggedInGuard],
        data: {
          title: 'adrHazardousSubstanceTransport',
          module: ModuleName.HAZARDOUS_SUBSTANCE,
          color: ModuleColor.HAZARDOUS_SUBSTANCE,
        },
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FrontendRoutingModule {
}
